<template>
  <a-skeleton active class="loading p-8" v-if="isLoading" />

  <div class="layout" v-else>
    <div class="layout-bar">
      <div class="layout-bar-header">
        <a-typography-title :level="3">{{$t('fleet-page.fleet')}}</a-typography-title>

        <div class="search">
          <a-input
            :placeholder="$t('fleet-page.search')"
            v-model:value="stateSearch.result"
            @change="handleChangeSearch"
          />

          <SearchOutlined class="search-icon" />
        </div>
      </div>

      <div class="layout-bar-body">
        <perfect-scrollbar>
          <a
            v-for="item in stateVessels.list"
            :key="item.imo"
            :class="{ active: item.imo == selectedVessel.imo }"
            @click.prevent="handleSelectVessel(item.imo)"
          >
            {{ item.name }}
          </a>
        </perfect-scrollbar>
      </div>
    </div>

    <div class="layout-content" v-if="selectedVessel.imo != null">
      <div class="layout-content-header">
        <a-typography-title :level="3">
          {{ vesselDetails.name }}
        </a-typography-title>
      </div>

      <a-tabs v-model:activeKey="selectedVessel.tab" @change="changeTab">
        <a-tab-pane key="speed-fuel" :tab="$t('fleet-page.speed fuel')">
          <div class="layout-inner">
            <FleetSpeedFuel
              :vessel="vesselDetails"
              :loading="isLoadingVessel"
            />
          </div>
        </a-tab-pane>

        <a-tab-pane key="fuel-consumption" :tab="$t('fleet-page.fuel consumption')">
          <div class="layout-inner">
            <FleetFuelConsumption
              :vessel="vesselDetails"
              :loading="isLoadingVessel"
            />
          </div>
        </a-tab-pane>

        <a-tab-pane key="benchmarking" :tab="$t('fleet-page.benchmarking')">
          <div class="layout-inner">
            <FleetBenchmarking
              :vessel="vesselDetails"
              :loading="isLoadingVessel"
            />
          </div>
        </a-tab-pane>

        <a-tab-pane key="master-data" :tab="$t('fleet-page.master data')">
          <div class="layout-inner">
            <FleetMasterData
              :vessel="vesselDetails"
              :loading="isLoadingVessel"
            />
          </div>
        </a-tab-pane>


        <a-tab-pane key="route-optimizer" :tab="$t('fleet-page.voyage plan optimizer')">
          <div class="layout-inner">
            <FleetRouteOptimizer
            />
<!--            <FleetOptimizedRoute v-if="router.params.filter == 'optimized-route'"-->
<!--                :vessel="vesselDetails"-->
<!--                :loading="isLoadingVessel"-->
<!--            />-->
          </div>
        </a-tab-pane>

<!--        <a-tab-pane key="route-optimized" tab="FleetOptimizedRoute">-->
<!--          <div class="layout-inner">-->
<!--            <FleetOptimizedRoute-->
<!--                :loading="isLoadingVessel"-->
<!--            />-->
<!--          </div>-->
<!--        </a-tab-pane>-->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  watchEffect,
  reactive,
  computed,
  onMounted,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { SearchOutlined } from "@ant-design/icons-vue";

import { useLoading } from "@/composables/useLoading";
import useVessels from "@/composables/useVessels";

import {
  FleetSpeedFuel,
  FleetBenchmarking,
  FleetFuelConsumption,
  FleetMasterData,
  FleetRouteOptimizer,
} from "./components";

export default defineComponent({
  name: "Fleet",

  setup() {
    const route = useRoute();
    const router = useRouter();
    const imo = route.params.imo || null;
    const tab = route.params.tab || "speed-fuel";
    const stateSearch = reactive({ result: "" });
    const stateVessels = reactive({ list: [] });
    const selectedVessel = reactive({ imo: imo, tab: tab });

    const loading = useLoading();
    const vessels = useVessels();
    const loadingVessel = useLoading();

    const vesselsList = computed(() => vessels.vessels());
    const vesselDetails = computed(() => vessels.vesselDetails());

    onMounted(() => {
      loading.start();

      vessels.fetchVessels().then(() => {
        loading.finish();
      });
    });

    watchEffect(() => {
      selectedVessel.imo = route.params.imo || null;
      selectedVessel.tab = route.params.tab || "speed-fuel";

      if (!stateVessels.list.length) {
        stateVessels.list = vesselsList.value;
      }

      if (stateVessels.list.length)
      {
        if (selectedVessel.imo) {
          loadingVessel.start();

          const filteredVessels = vesselsList.value.filter((item) => {
            if (item.imo == selectedVessel.imo) {
              return item;
            }
          });

          if(filteredVessels[0]?.id){
              vessels.fetchVessel(filteredVessels[0].id).then(() => {
              loadingVessel.finish();
            });
          }
          else{          
            console.log("no vessels by imo, redirect to fleet page");
            router.push({ name: "Fleet" });
          }
        }
      }
    });

    const handleSelectVessel = (imo) => {
      if (selectedVessel.imo === imo) {
        return;
      }

      return router.push({
        name: "Fleet",
        params: {
          imo,
          tab: "speed-fuel",
          filter: "ballast",
        },
      });
    };

    const handleChangeSearch = (e) => {
      const value = e.target.value;

      stateSearch.result = value;

      if (value === "") {
        return (stateVessels.list = vesselsList.value);
      } else {
        stateVessels.list = vesselsList.value.filter((item) => {
          if (item.name.toLowerCase().includes(value.toLowerCase())) {
            return item;
          }
        });
      }
    };

    const routeFilerByTab = (tab) => {
      switch (tab) {
        case 'speed-fuel':
          return "ballast";
        case 'route-optimizer':
          return "route-for-optimize";
        case 'fuel-consumption':
          return "total";
        default:
          return "";
      }
    }

    const changeTab = () => {
      router.push({
        name: "Fleet",
        params: {
          imo: selectedVessel.imo,
          tab: selectedVessel.tab,
          // filter: selectedVessel.tab == "speed-fuel" ? "ballast" : "total",
          // filter: selectedVessel.tab == "speed-fuel" ? "ballast" : (selectedVessel.tab == "route-optimizer" ? "route-for-optimize" : "total"),
          filter: routeFilerByTab(selectedVessel.tab)
        },
      });
    };

    return {
      stateSearch,
      stateVessels,
      vesselDetails,
      selectedVessel,
      isLoading: loading.active,
      isLoadingVessel: loadingVessel.active,
      handleSelectVessel,
      handleChangeSearch,
      changeTab,
    };
  },

  components: {
    SearchOutlined,
    FleetSpeedFuel,
    FleetBenchmarking,
    FleetFuelConsumption,
    FleetMasterData,
    FleetRouteOptimizer,
  },
});
</script>

<style lang="less" scoped>
.p-8 {
  padding: 8px;
}

.ps {
  height: 100%;
}

.layout {
  height: calc(100vh - 5.4rem);
  display: flex;

  &-bar {
    width: 29.2rem;
    background-color: @color-body;
    border-right: 0.1rem solid @color-border;

    &-header {
      padding: 2.8rem;

      h3.ant-typography {
        text-transform: uppercase;
        margin-bottom: 2.4rem;
      }
    }

    &-body {
      height: calc(100vh - 20rem);

      a {
        color: @color-primary;
        display: block;
        font-size: 1.5rem;
        padding: 1.4rem 2.4rem;
        font-weight: bold;
        &.active {
          background-color: @color-active;
        }
      }
    }
  }

  &-content {
    width: calc(100% - 29.2rem);
    display: flex;
    flex-direction: column;

    &-header {
      height: 8rem;
      background-color: @color-body;
      display: flex;
      align-items: center;
      padding: 0 2.2rem;
      text-transform: uppercase;
    }
  }

  &-inner {
    height: calc(100vh - 18.6rem);
    display: flex;
  }
}

.search {
  width: 24rem;
  position: relative;

  .ant-input {
    border-radius: 0px;
    padding-left: 1.6rem;
    padding-right: 3.6rem;
  }

  &-icon {
    color: @color-secondary;
    font-size: 1.8rem;
    position: absolute;
    top: 0.8rem;
    right: 1rem;
  }
}
</style>
