<template>
  <div class="layout-chart">
    <ModalNoonReport
      :visible="modalVisible"
      :onCancel="() => handleOpenNoonReport(false)"
    />

    <div class="layout-chart-title">FO ME consumption [mts/d]</div>
    <div class="layout-chart-subtitle">STW (kn)</div>

    <vue-echarts
      ref="chartSpeedFuel"
      v-if="dataSource.id"
      :option="state.option"
      :style="`height: 100%`"
      autoresize
    />
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  watchEffect,
  computed,
  reactive,
  onMounted,
} from "vue";
import { VueEcharts } from "vue3-echarts";

import { date, convertation } from "@/helpers";

import useReports from "@/composables/useReports";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "FleetSpeedFuelChart",

  props: {
    type: {
      type: String,
      default: "BALLAST",
    },
    dataSource: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const chartSpeedFuel = ref(null);
    const modalVisible = ref(false);
    
    const { t } = useI18n();

    const state = reactive({
      option: {
        grid: {
          top: "6%",
          left: "7%",
          right: "4%",
          bottom: "6%",
        },
        legend: {
          top: 20,
          left: "5%",
          itemGap: 20,
          lineStyle: {
            width: 2,
          },
          textStyle: {
            width: 230,
            color: "#9FA2AE",
            fontSize: 12,
            overflow: "truncate",
          },
          data: [
            {
              name: t('fleet-page.theoretical fuel consumption'),
              itemStyle: {
                opacity: 0,
              },
            },
            {
              name:   t('fleet-page.reported fuel consumption'),
              itemStyle: {
                opacity: 0,
              },
            },
            {
              name: "Noon report data point",
            },
            {
              name: "Suspicious noon report data point",
            },
            {
              name: "Service speed",
              itemStyle: {
                opacity: 0,
              },
              lineStyle: {
                type: "dashed",
                width: 1,
              },
            },
          ],
        },
        xAxis: {
          splitLine: {
            lineStyle: {
              color: "#4B4C53",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
        },
        yAxis: {
          boundaryGap: true,
          splitLine: {
            lineStyle: {
              color: "#4B4C53",
            },
          },
          axisLine: { onZero: false },
          axisLabel: {
            show: true,
            color: "#666971",
            fontSize: 14,
          },
        },
      },
    });

    const stateReports = reactive({
      list: [],
    });

    const reports = useReports();
    const reportsList = computed(() => reports.noonReports());

    onMounted(() => {
      if (!reportsList.value.length) {
        reports.fetchNoonReports();
      }
    });

    watchEffect(() => {
      if (props.dataSource.id) {
        const { speed_fuel_curve } = props.dataSource;

        const speedData =
          props.type === "ballast"
            ? speed_fuel_curve.ballast
            : speed_fuel_curve.laden;

        state.option.tooltip = {
          axisPointer: {
            type: "scatter",
          },
          position: "top",
          backgroundColor: "#2e2f33",
          borderColor: "#000",
          padding: [10, 15],
          textStyle: {
            color: "#fff",
          },
          transitionDuration: 0,
          formatter: (params) => {
            if (
              params.seriesName === "Noon report data point" ||
              params.seriesName === "Suspicious noon report data point"
            ) {
              let tooltipData = null;
              
              stateReports.list.map((item) => {
                if (item.date_report === params.data.date) {
                  tooltipData = item;
                }
              });

              if (tooltipData) {
                return `
                  Date:
                  ${date.formatUTC(
                    tooltipData.date_report,
                    "dd MMM yyyy, HH:mm"
                  )}<br />
                  COG: ${tooltipData.cog}°<br />
                  SOG: ${tooltipData.sog} kn<br />
                  Position:
                  ${convertation.convertToDms(tooltipData.position_convert[0])}
                  ${convertation.convertToDms(tooltipData.position_convert[1])}
                `;
              }
            }
          },
        };

        state.option.series = [
          {
            name: t('fleet-page.noon report data point'),
            type: "scatter",
            data: speedData.reported_data.normal.map((item) => ({
              date: item.date,
              value: item.coord,
            })),
            itemStyle: {
              color: "#587AFF",
            },
          },
          {
            name: t('fleet-page.suspicious noon report data point'),
            type: "scatter",
            data: speedData.reported_data.suspicious.map((item) => ({
              date: item.date,
              value: item.coord,
            })),
            itemStyle: {
              color: "#FFA800",
            },
          },
          {
            name: t('fleet-page.theoretical fuel consumption'),
            type: "line",
            smooth: true,
            color: "#60B6E7",
            symbolSize: 0,
            lineStyle: {
              color: "#60B6E7",
            },
            data: speedData.speed_fuel_curves.theoretical,
          },
          {
            name: t('fleet-page.reported fuel consumption'),
            type: "line",
            smooth: true,
            color: "#F19AFF",
            symbolSize: 0,
            lineStyle: {
              color: "#F19AFF",
            },
            data: speedData.speed_fuel_curves.reported,
          },
          {
            name: t('fleet-page.service speed'),
            type: "line",
            smooth: true,
            color: "#ABABAB",
            symbolSize: 0,
            lineStyle: {
              color: "#ABABAB",
              type: "dashed",
              width: 1,
            },
            markLine: {
              symbol: ["none", "none"],
              label: { show: false },
              data: [{ xAxis: speedData.me_fuel_cons_data.service_speed }],
            },
          },
        ];

        if (chartSpeedFuel.value) {
          chartSpeedFuel.value.chart.on("click", (params) => {
            if (
              params.seriesName === "Noon report data point" ||
              params.seriesName === "Suspicious noon report data point"
            ) {
              handleOpenNoonReport(true);
            }
          });

          chartSpeedFuel.value.refreshOption();
        }
      }

      if (!stateReports.list.length) {
        stateReports.list = reportsList.value;
      }
    });

    const handleOpenNoonReport = (status) => {
      modalVisible.value = status;
    };

    return {
      state,
      chartSpeedFuel,
      handleOpenNoonReport,
      modalVisible,
    };
  },

  components: {
    VueEcharts,
  },
});
</script>

<style lang="less" scoped>
.layout-chart {
  width: 100%;
  height: calc(100vh - 18.2rem);
  position: relative;

  &-title {
    color: @color-secondary;
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    left: -6.4rem;
    transform: rotate(-90deg);
  }

  &-subtitle {
    color: @color-secondary;
    font-size: 1.4rem;
    position: absolute;
    bottom: 1.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>