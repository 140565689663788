<template>
  <div class="speed-fuel">
    <div class="sidebar">
      <a-skeleton v-if="loading" class="details details-skeleton" active />

      <div class="details" v-else>
        <div class="details-inner">
          <div class="details-header">
            <ul class="details-list basic clean">
              <li>
                <div>
                  <a-typography-text type="secondary">
                    {{$t('fleet-page.vessel condition:')}}
                  </a-typography-text>
                </div>

                <a-radio-group
                  v-model:value="state.filter"
                  @change="changeTab"
                  button-style="solid"
                  style="width: 174px"
                >
                  <a-radio-button value="ballast">{{$t('fleet-page.ballast')}}</a-radio-button>
                  <a-radio-button value="laden">{{$t('fleet-page.laden')}}</a-radio-button>
                </a-radio-group>
              </li>

              <li>
                <div>
                  <a-typography-text type="secondary">
                    {{$t('fleet-page.analyzed period')}}
                  </a-typography-text>
                </div>

                <a-select placeholder="12 months" style="width: 174px" value="1">
                  <a-select-option value="1">12 months</a-select-option>
                </a-select>
              </li>
            </ul>
          </div>

          <a-tabs v-model:activeKey="state.filter">
            <a-tab-pane
              key="ballast"
              v-if="vessel.speed_fuel_curve && vessel.speed_fuel_curve.ballast"
            >
              <div class="details-body">
                <perfect-scrollbar>
                  <ul class="details-list clean">
                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.last hull cleaning date')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.ballast
                            .last_hull_cleaning_date
                        }}
                      </a-typography-text>
                    </li>
                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.data sources')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.ballast.data_sources.join(
                            ", "
                          )
                        }}
                      </a-typography-text>
                    </li>
                  </ul>

                  <a-typography-title :level="4">
                    ME fuel consumption data
                  </a-typography-title>

                  <ul
                    class="details-list"
                    v-if="vessel.speed_fuel_curve.ballast.me_fuel_cons_data"
                  >
                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.service speed')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          kFormatter.format(
                            vessel.speed_fuel_curve.ballast.me_fuel_cons_data
                              .service_speed,
                            $t('units.kn')
                          )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.fuel consumption reported')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          kFormatter.format(
                            vessel.speed_fuel_curve.ballast.me_fuel_cons_data
                              .fuel_cons_reported,
                            $t('units.mts/d')
                          )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.fuel consumption theoretical')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          kFormatter.format(
                            vessel.speed_fuel_curve.ballast.me_fuel_cons_data
                              .fuel_cons_theoretical,
                            $t('units.mts/d')
                          )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.fuel consumption trend')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          kFormatter.format(
                            vessel.speed_fuel_curve.ballast.me_fuel_cons_data
                              .fuel_cons_trend,
                            "%"
                          )
                        }}
                      </a-typography-text>
                    </li>

                    <li
                      class="not-bordered"
                      v-if="
                        vessel.speed_fuel_curve.ballast.me_fuel_cons_data
                          .prop_check_alarm
                      "
                    >
                      <div class="details-alert">
                        <img :src="icons.warningRed" alt="warning" />
                        {{$t('fleet-page.propeller check recommended')}}
                      </div>
                    </li>
                  </ul>

                  <a-typography-title :level="4">
                    {{$t('fleet-page.hull fouling conditions: days spent')}}
                  </a-typography-title>

                  <ul
                    class="details-list"
                    v-if="
                      vessel.speed_fuel_curve.ballast.hull_fouling_conditions
                    "
                  >
                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.polar waters')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.ballast.hull_fouling_conditions.polar_waters > 120
                              ?
                              kFormatter.format(
                                  vessel.speed_fuel_curve.ballast.hull_fouling_conditions.polar_waters / 24,
                                  $t('units.days')
                              )
                              :
                              kFormatter.format(
                                  vessel.speed_fuel_curve.ballast.hull_fouling_conditions.polar_waters,
                                  $t('units.hrs')
                              )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.temperature waters')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.ballast.hull_fouling_conditions.temperature_waters > 120
                              ?
                              kFormatter.format(
                                  vessel.speed_fuel_curve.ballast.hull_fouling_conditions.temperature_waters / 24,
                                  $t('units.days')
                              )
                              :
                              kFormatter.format(
                                  vessel.speed_fuel_curve.ballast.hull_fouling_conditions.temperature_waters,
                                  $t('units.hrs')
                              )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.tropical waters')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.ballast.hull_fouling_conditions.tropical_waters > 120
                              ?
                              kFormatter.format(
                                  vessel.speed_fuel_curve.ballast.hull_fouling_conditions.tropical_waters / 24,
                                  $t('units.days')
                              )
                              :
                              kFormatter.format(
                                  vessel.speed_fuel_curve.ballast.hull_fouling_conditions.tropical_waters,
                                  $t('units.hrs')
                              )
                        }}
                      </a-typography-text>
                    </li>
                  </ul>
                </perfect-scrollbar>
              </div>
            </a-tab-pane>
            <a-tab-pane
              key="laden"
              v-if="vessel.speed_fuel_curve && vessel.speed_fuel_curve.laden"
            >
              <div class="details-body">
                <perfect-scrollbar>
                  <ul class="details-list clean">
                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.last hull cleaning date')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.laden.last_hull_cleaning_date
                        }}
                      </a-typography-text>
                    </li>
                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.data sources')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.laden.data_sources.join(", ")
                        }}
                      </a-typography-text>
                    </li>
                  </ul>

                  <a-typography-title :level="4">
                    ME fuel consumption data
                  </a-typography-title>

                  <ul
                    class="details-list"
                    v-if="vessel.speed_fuel_curve.laden.me_fuel_cons_data"
                  >
                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.service speed')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          kFormatter.format(
                            vessel.speed_fuel_curve.laden.me_fuel_cons_data
                              .service_speed,
                              $t('units.kn')
                          )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.fuel consumption reported')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          kFormatter.format(
                            vessel.speed_fuel_curve.laden.me_fuel_cons_data
                              .fuel_cons_reported,
                            $t('units.mts/d')
                          )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.fuel consumption theoretical')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          kFormatter.format(
                            vessel.speed_fuel_curve.laden.me_fuel_cons_data
                              .fuel_cons_theoretical,
                            $t('units.mts/d')
                          )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.fuel consumption trend')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          kFormatter.format(
                            vessel.speed_fuel_curve.laden.me_fuel_cons_data
                              .fuel_cons_trend,
                            "%"
                          )
                        }}
                      </a-typography-text>
                    </li>

                    <li
                      class="not-bordered"
                      v-if="
                        vessel.speed_fuel_curve.laden.me_fuel_cons_data
                          .prop_check_alarm
                      "
                    >
                      <div class="details-alert">
                        <img :src="icons.warningRed" alt="warning" />
                        {{$t('fleet-page.propeller check recommended')}}
                      </div>
                    </li>
                  </ul>

                  <a-typography-title :level="4">
                    {{$t('fleet-page.hull fouling conditions: days spent')}}
                  </a-typography-title>

                  <ul
                    class="details-list"
                    v-if="vessel.speed_fuel_curve.laden.hull_fouling_conditions"
                  >
                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.polar waters')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.laden.hull_fouling_conditions.polar_waters > 120
                              ?
                              kFormatter.format(
                                  vessel.speed_fuel_curve.laden.hull_fouling_conditions.polar_waters / 24,
                                  $t('units.days')
                              )
                              :
                              kFormatter.format(
                                  vessel.speed_fuel_curve.laden.hull_fouling_conditions.polar_waters,
                                  $t('units.hrs')
                              )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.temperature waters')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.laden.hull_fouling_conditions.temperature_waters > 120
                              ?
                              kFormatter.format(
                                  vessel.speed_fuel_curve.laden.hull_fouling_conditions.temperature_waters / 24,
                                  $t('units.days')
                              )
                              :
                              kFormatter.format(
                                  vessel.speed_fuel_curve.laden.hull_fouling_conditions.temperature_waters,
                                  $t('units.hrs')
                              )
                        }}
                      </a-typography-text>
                    </li>

                    <li>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.tropical waters')}}
                      </a-typography-text>
                      <a-typography-text>
                        {{
                          vessel.speed_fuel_curve.laden.hull_fouling_conditions.tropical_waters > 120
                              ?
                              kFormatter.format(
                                  vessel.speed_fuel_curve.laden.hull_fouling_conditions.tropical_waters / 24,
                                  $t('units.days')
                              )
                              :
                              kFormatter.format(
                                  vessel.speed_fuel_curve.laden.hull_fouling_conditions.tropical_waters,
                                  $t('units.hrs')
                              )
                        }}
                      </a-typography-text>
                    </li>
                  </ul>
                </perfect-scrollbar>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>

    <div class="chart">
      <FleetSpeedFuelChart :type="state.filter" :dataSource="vessel" />
    </div>
  </div>
</template>

<script>
import { icons } from "@/assets";
import { defineComponent, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import FleetSpeedFuelChart from "./FleetSpeedFuelChart.vue";
import { kFormatter } from "@/helpers";

export default defineComponent({
  name: "FleetSpeedFuel",

  props: {
    vessel: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },

  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const filter = route.params.filter || "ballast";
    const state = reactive({ filter: filter });

    watchEffect(() => {
      state.filter = route.params.filter || "ballast";
    });

    const changeTab = () => {
      console.log("changeTab");

      router.push({
        name: "Fleet",
        params: {
          imo: props.vessel.imo,
          tab: route.params.tab,
          filter: state.filter,
        },
      });
    };

    return {
      icons,
      state,
      kFormatter,
      changeTab,
    };
  },

  components: {
    FleetSpeedFuelChart,
  },
});
</script>

<style lang="less" scoped>
.speed-fuel {
  display: flex;
  width: 100%;

  .chart {
    width: 100%;
    height: 100%;
  }

  .sidebar {
    width: 43.5rem;
    background-color: @color-white;
    box-shadow: -0.3rem 0 0.6rem rgba(@color-black, 0.25);

    .details {
      width: 43.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      overflow-y: auto;

      &.details-skeleton {
        padding: 1.2rem 2.4rem;
      }

      &-header {
        background-color: @color-white-panel;
        padding: 1.6rem 2.5rem;

        .ant-radio-group:deep {
          .ant-radio-button-wrapper {
            width: 50%;
            text-align: center;
            &:not(.ant-radio-button-wrapper-checked){
              border-color: @input-border-color;
            }
          }
        }

        .details-list {
          margin-bottom: 0;
        }
      }

      &-body {
        height: calc(100vh - 33.5rem);
        padding: 3.2rem 1rem 3.2rem 2.5rem;

        .ps {
          height: 100%;
          padding-right: 1.5rem;
        }

        h4.ant-typography {
          color: @color-black;
          font-size: 1.8rem;
          margin-bottom: 12px;
        }
      }

      &-list {
        list-style: none;
        margin-bottom: 5.4rem;
        padding: 0;

        &.clean li {
          margin-left: 0;
        }

        &.basic li {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          border-bottom: none;
          margin-bottom: 12px;
        }

        &.basic li .ant-typography {
          background-color: @color-white-panel;
        }

        li {
          border-bottom: 0.1rem dashed @color-border;
          font-size: 1.6rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.8rem 0 0 2rem;

          &.not-bordered {
            border: 0;
          }

          .ant-btn {
            top: 0.8rem;
            width: 100%;
          }

          .ant-typography {
            //background-color: @color-black;
            padding: 0 0.5rem;
            top: 0.8rem;
            position: relative;
          }
        }
      }

      &-alert {
        width: 100%;
        border: 0.1rem solid @color-red;
        border-radius: 0px;
        color: @color-red;
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        justify-content: center;
        position: relative;
        padding: 0.4rem 0;
        margin-top: 2rem;

        img {
          position: absolute;
          top: 50%;
          left: 1.2rem;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>