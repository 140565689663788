<template>
  <div class="fleat-benchmarking">
    <a-skeleton v-if="loading" active />

    <div class="fleat-benchmarking" v-else>
      <div class="sidebar">
        <div class="details">
          <div class="details-inner">
            <perfect-scrollbar>
              <div class="details-header">
                <ul class="details-list basic clean">
                  <li>
                    <div>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.vessel period')}}
                      </a-typography-text>
                    </div>

                    <a-select
                      placeholder="2021"
                      style="width: 174px"
                      v-model:value="state.form.shipsPeriod"
                      :disabled="true"
                    >
                      <a-select-option value="2021">2021</a-select-option>
                      <a-select-option value="2020">2020</a-select-option>
                      <a-select-option value="2019">2019</a-select-option>
                      <a-select-option value="2018">2018</a-select-option>
                    </a-select>
                  </li>

                  <a-typography-text>{{$t('fleet-page.benchmarking parameters')}}</a-typography-text>

                  <li>
                    <div>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.period:')}}
                      </a-typography-text>
                    </div>

                    <a-select
                      placeholder="2020"
                      style="width: 174px"
                      v-model:value="state.form.benchmarkingPeriod"
                    >
                      <a-select-option value="2020">2020</a-select-option>
                      <a-select-option value="2019">2019</a-select-option>
                      <a-select-option value="2018">2018</a-select-option>
                    </a-select>
                  </li>

                  <li>
                    <div>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.vessel type:')}}
                      </a-typography-text>
                    </div>

                    <a-select
                      placeholder="Tanker"
                      style="width: 174px"
                      v-model:value="state.form.benchmarkingType"
                      :disabled="true"
                    >
                      <a-select-option value="Tanker">Tanker</a-select-option>
                      <a-select-option value="General Cargo"
                        >General Cargo</a-select-option
                      >
                      <a-select-option value="Gas Carrier"
                        >Gas Carrier</a-select-option
                      >
                    </a-select>
                  </li>

                  <li>
                    <div>
                      <a-switch
                          v-model:checked="state.form.specificIMO"
                          :disabled="true"
                      />
                    </div>

                    <div>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.specific IMO')}}
                      </a-typography-text>
                    </div>
                  </li>

                  <li>
                    <div>
                      <a-switch
                        v-model:checked="state.form.averageDeadweight"
                        :disabled="true"
                      />
                    </div>

                    <div>
                      <a-typography-text type="secondary">
                        {{$t('fleet-page.average deadweight from')}}
                      </a-typography-text>
                    </div>
                  </li>

                  <li class="form" v-show="state.form.averageDeadweight">
                    <div>From</div>
                    <a-input placeholder="0" />

                    <div>To</div>
                    <a-input placeholder="0" />
                  </li>

                  <a-button
                    type="primary"
                    @click="handleCalculate"
                    html-type="submit"
                    >{{$t('fleet-page.calculate')}}</a-button
                  >
                </ul>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="count">
          <a-typography-text>
            {{$t('fleet-page.result based on ships', {num: state.items.vessels_count ?? 0 })}}
          </a-typography-text>
        </div>
          <div class="items">
            <div v-for="(item, index) in items" :key="index">
              <template v-if="index == 'utilization_rate'">
                <div class="item">
                  <div class="title">
                    <a-typography-text>{{$t('fleet-page.utilization rate')}}</a-typography-text>
                  </div>

                  <div class="results">
                    <div class="result">
                      <div class="label">{{ vessel.name }}</div>

                      <div class="value">
                        {{ item.value }} <span class="placeholder">%</span>
                      </div>
                    </div>

                    <div class="result" v-if="!item.abs_diff && !item.abs_diff">
                      <a-statistic
                        :groupSeparator='""'
                        :title="$t('fleet-page.difference')"
                        :value-style="{
                          color: item.value > 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :value="item.difference"
                      >
                        <template #suffix>
                          <a-typography-text
                            :type="item.value > 0 ? 'success' : 'danger'"
                          >
                            %
                          </a-typography-text>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result">
                      <div class="label">{{$t('fleet-page.benchmark')}}</div>

                      <div class="value_rotated">
                        {{ item.benchmark }}
                        <span class="placeholder">%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="index == 'annual_mileage'">
                <div class="item">
                  <div class="title">
                    <a-typography-text>{{$t('fleet-page.annual mileage')}}</a-typography-text>
                  </div>

                  <div class="results">
                    <div class="result">
                      <div class="label">{{ vessel.name }}</div>

                      <div class="value">
                        {{ item.value }}
                        <span class="placeholder">NM</span>
                      </div>
                    </div>

                    <div class="result" v-if="item.abs_diff">
                      <a-statistic
                        :groupSeparator='""'
                        :title="$t('fleet-page.absolute diff')"
                        :value-style="{
                          color: item.rel_diff > 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :value="item.abs_diff"
                      >
                        <template #suffix>
                          <a-space align="center">
                            <a-typography-text
                              :type="item.rel_diff > 0 ? 'success' : 'danger'"
                            >
                              NM
                            </a-typography-text>
                          </a-space>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result" v-if="item.rel_diff">
                      <a-statistic
                        :groupSeparator='""'
                        :value-style="{
                          color: item.rel_diff > 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :title="$t('fleet-page.relative diff')"
                        :value="item.rel_diff"
                      >
                        <template #suffix>
                          <a-typography-text
                            :type="item.rel_diff > 0 ? 'success' : 'danger'"
                          >
                            %
                          </a-typography-text>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result">
                      <div class="label">{{$t('fleet-page.benchmark')}}</div>

                      <div class="value_rotated">
                        {{ item.benchmark }}
                        <span class="placeholder">NM</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="index == 'total_fuel_consumption'">
                <div class="item">
                  <div class="title">
                    <a-typography-text
                      >{{$t('fleet-page.total fuel consumption')}}</a-typography-text
                    >
                  </div>

                  <div class="results">
                    <div class="result">
                      <div class="label">{{ vessel.name }}</div>

                      <div class="value">
                        {{ item.value }}
                        <span class="placeholder">mts</span>
                      </div>
                    </div>

                    <div class="result" v-if="item.abs_diff">
                      <a-statistic
                        :groupSeparator='""'
                        :title="$t('fleet-page.absolute diff')"
                        :value-style="{
                          color: item.rel_diff < 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :value="item.abs_diff"
                      >
                        <template #suffix>
                          <a-space align="center">
                            <a-typography-text
                              :type="item.rel_diff < 0 ? 'success' : 'danger'"
                            >
                              mts
                            </a-typography-text>
                          </a-space>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result" v-if="item.rel_diff">
                      <a-statistic
                        :groupSeparator='""'
                        :value-style="{
                          color: item.rel_diff < 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :title="$t('fleet-page.relative diff')"
                        :value="item.rel_diff"
                      >
                        <template #suffix>
                          <a-typography-text
                            :type="item.rel_diff < 0 ? 'success' : 'danger'"
                          >
                            %
                          </a-typography-text>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result">
                      <div class="label">{{$t('fleet-page.benchmark')}}</div>

                      <div class="value_rotated">
                        {{ item.benchmark }}                         
                        <span class="placeholder">mts</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="index == 'total_co2_emissions'">
                <div class="item">
                  <div class="title">
                    <a-typography-text>{{$t('fleet-page.total CO2 emissions')}}</a-typography-text>
                  </div>

                  <div class="results">
                    <div class="result">
                      <div class="label">{{ vessel.name }}</div>

                      <div class="value">
                        {{ item.value }}
                        <span class="placeholder">mts</span>
                      </div>
                    </div>

                    <div class="result" v-if="item.abs_diff">
                      <a-statistic
                        :groupSeparator='""'
                        :title="$t('fleet-page.absolute diff')"
                        :value-style="{
                          color: item.rel_diff < 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :value="item.abs_diff"
                      >
                        <template #suffix>
                          <a-space align="center">
                            <a-typography-text
                              :type="item.rel_diff < 0 ? 'success' : 'danger'"
                            >
                              mts
                            </a-typography-text>
                          </a-space>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result" v-if="item.rel_diff">
                      <a-statistic
                        :groupSeparator='""'
                        :value-style="{
                          color: item.rel_diff < 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :title="$t('fleet-page.relative diff')"
                        :value="item.rel_diff"
                      >
                        <template #suffix>
                          <a-typography-text
                            :type="item.rel_diff < 0 ? 'success' : 'danger'"
                          >
                            %
                          </a-typography-text>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result">
                      <div class="label">{{$t('fleet-page.benchmark')}}</div>

                      <div class="value_rotated">
                        {{ item.benchmark }}
                        <span class="placeholder">mts</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="index == 'co2_emissions_per_distance'">
                <div class="item">
                  <div class="title">
                    <a-typography-text
                      >{{$t('fleet-page.CO2 emissions per distance')}}</a-typography-text
                    >
                  </div>

                  <div class="results">
                    <div class="result">
                      <div class="label">{{ vessel.name }}</div>

                      <div class="value">
                        {{ item.value }}
                        <span class="placeholder">kg CO2/NM</span>
                      </div>
                    </div>

                    <div class="result" v-if="item.abs_diff">
                      <a-statistic
                        :title="$t('fleet-page.absolute diff')"
                        :value-style="{
                          color: item.rel_diff < 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :value="item.abs_diff"
                      >
                        <template #suffix>
                          <a-space align="center">
                            <a-typography-text
                              :type="item.rel_diff < 0 ? 'success' : 'danger'"
                            >
                              kg CO2/NM
                            </a-typography-text>
                          </a-space>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result" v-if="item.rel_diff">
                      <a-statistic
                        :value-style="{
                          color: item.rel_diff < 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :title="$t('fleet-page.relative diff')"
                        :value="item.rel_diff"
                      >
                        <template #suffix>
                          <a-typography-text
                            :type="item.rel_diff < 0 ? 'success' : 'danger'"
                          >
                            %
                          </a-typography-text>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result">
                      <div class="label">{{$t('fleet-page.benchmark')}}</div>

                      <div class="value_rotated">
                        {{ item.benchmark }}
                        <span class="placeholder">kg CO2/NM</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template v-if="index == 'annual_total_time_spent_at_sea'">
                <div class="item">
                  <div class="title">
                    <a-typography-text
                      >{{$t('fleet-page.annual total time spent at sea')}}</a-typography-text
                    >
                  </div>

                  <div class="results">
                    <div class="result">
                      <div class="label">{{ vessel.name }}</div>

                      <div
                        class="value"
                        v-html="convertMinutes(item.value)"
                      ></div>
                    </div>

                    <div class="result" v-if="item.abs_diff">
                      <a-statistic
                        :title="$t('fleet-page.absolute diff')"
                        :value-style="{
                          color: item.rel_diff > 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :value="item.abs_diff"
                      >
                        <template #suffix>
                          <a-space align="center">
                            <a-typography-text
                              :type="item.rel_diff > 0 ? 'success' : 'danger'"
                            >
                              days
                            </a-typography-text>
                          </a-space>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result" v-if="item.rel_diff">
                      <a-statistic
                        :value-style="{
                          color: item.rel_diff > 0 ? '#47BF2F' : '#FF3131',
                        }"
                        :title="$t('fleet-page.relative diff')"
                        :value="item.rel_diff"
                      >
                        <template #suffix>
                          <a-typography-text
                            :type="item.rel_diff > 0 ? 'success' : 'danger'"
                          >
                            %
                          </a-typography-text>
                        </template>
                      </a-statistic>
                    </div>

                    <div class="result">
                      <div class="label">{{$t('fleet-page.benchmark')}}</div>

                      <div
                        class="value_rotated"
                        v-html="convertMinutes(item.benchmark)"
                      ></div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, watchEffect, reactive, computed } from "vue";

export default defineComponent({
  name: "FleetBenchmarking",

  props: {
    vessel: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => true,
    },
  },

  setup(props) {
    const state = reactive({
      form: {
        shipsPeriod: "2021",
        benchmarkingPeriod: "2020",
        benchmarkingType: "Tanker",
        specificIMO: false,
        averageDeadweight: false,
        averageDeadweightFrom: "",
        averageDeadweightto: "",
      },

      vessel: null,
      items: {},
    });

    watchEffect(() => {
      if (state.vessel && props.vessel.id != state.vessel.id) {
        state.items = {};
      }
    });

    const items = computed(() => {
      return state.items;
    });

    const convertMinutes = (count) => {
      const days = Math.floor(count / 1440);
      const hours = Math.floor((count - days * 1440) / 60);
      const minutes = Math.round(count % 60);

      if (days > 0) {
        return `${days} <span class="placeholder">d</span> ${hours} <span class="placeholder">h</span> ${minutes} <span class="placeholder">m</span>`;
      } else {
        return `${hours} <span class="placeholder">h</span> ${minutes} <span class="placeholder">m</span>`;
      }
    };

    const handleCalculate = () => {
      state.vessel = props.vessel;
      state.items = {};

      switch (state.form.benchmarkingPeriod) {
        case "2018":
          state.items = props.vessel.mrv_benchmarking_2018;
          break;
        case "2019":
          state.items = props.vessel.mrv_benchmarking_2019;
          break;
        case "2020":
          state.items = props.vessel.mrv_benchmarking_2020;
          break;
      }
    };

    return {
      state,
      items,
      convertMinutes,
      handleCalculate,
    };
  },
});
</script>

<style lang="less">
span.placeholder {
  color: #9fa2ae;
  font-size: 1.2rem;
  vertical-align: text-top;
}
</style>

<style lang="less" scoped>
.fleat-benchmarking {
  display: flex;
  width: 100%;

  .sidebar {
    width: 43.5rem;
    background-color: @color-white;
    box-shadow: -0.3rem 0 0.6rem rgba(@color-black, 0.25);
    border-right: 0.1rem solid @color-border;

    .details {
      width: 43.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      overflow-y: auto;

      &-header {
        padding: 1.6rem 2.5rem;

        .ant-radio-group:deep {
          .ant-radio-button-wrapper {
            width: 50%;
            text-align: center;
          }
        }
      }

      &-list {
        list-style: none;
        margin-bottom: 5.4rem;
        padding: 0;

        &.clean li {
          margin-left: 0;
        }

        &.basic li {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          border-bottom: none;
          margin-bottom: 12px;
        }

        .ant-btn {
          margin-top: 12px;
        }

        li.form {
          display: flex;
          padding: 10px 0;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }

        li {
          border-bottom: 0.1rem dashed @color-border;
          font-size: 1.6rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0.8rem 0 0 2rem;

          &.not-bordered {
            border: 0;
          }

          .ant-btn {
            top: 0.8rem;
            width: 100%;
          }

          .ant-typography {
            top: 0.4rem;
            position: relative;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: @color-white;

    .count {
      font-size: 15px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0 20px;

      .ant-typography {
        color: @color-secondary;
      }
    }

    .items {
      width: 100%;
      padding: 0rem 2.4rem;

      .item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 0.1rem solid @color-border;

        .title {
          width: 43.5rem;
          font-size: 18px;
          font-weight: 500;
          text-transform: uppercase;
        }

        .results {
          width: 100%;
          display: flex;
          height: 150px;

          padding: 0rem 2.4rem;

          align-items: center;
          justify-content: space-between;
          flex-wrap: nowrap;
          flex-direction: row;
          border-left: 0.1rem solid @color-border;

          .result {
            width: 100%;
            text-align: center;

            :deep(.ant-statistic-content-suffix) {
              color: @color-secondary;
              font-size: 1.2rem;
              vertical-align: text-top;
            }

            &:first-child {
              text-align: left;
            }

            &:last-child {
              text-align: right;
            }

            .label {
              font-size: 14px;
              color: @color-secondary;
              margin-bottom: 10px;
            }

            .value {
              font-size: 1.6rem;
              position: relative;
              display: inline-block;
              background-color: @color-value-bg;
              padding: 5px 10px;

              &:before {
                content: "";
                position: absolute;
                right: -10px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 11px solid @color-value-bg;
                border-top: 17.8px solid transparent;
                border-bottom: 17.8px solid transparent;
              }

              .placeholder {
                color: @color-secondary;
                font-size: 1.2rem;
                vertical-align: text-top;
              }
            }

            .value_rotated {
              font-size: 1.6rem;
              position: relative;
              display: inline-block;
              background-color: @color-value-bg;
              padding: 5px 10px;

              &:before {
                content: "";
                position: absolute;
                left: -10px;
                bottom: 0;
                width: 0;
                height: 0;
                border-right: 11px solid @color-value-bg;
                border-top: 17.8px solid transparent;
                border-bottom: 17.8px solid transparent;
              }

              .placeholder {
                color: @color-secondary;
                font-size: 1.2rem;
                vertical-align: text-top;
              }
            }
          }
        }
      }
    }
  }
}
</style>